<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div
            class="vx-row no-gutter justify-center items-center"
          >
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                src="@/assets/images/logo/flipmoney.png"
                width="250px"
                alt="register"
                class="mx-auto"
              />
            </div>

            <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4 d-theme-dark-bg">
              <div class="px-8 pt-8 login-tabs-container">
                  <h4 class="text-center">Email verified successfully</h4>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
  export default {
    data() {
      return {
      }
    }
  }

</script>